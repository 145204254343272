import { useStaticQuery, graphql } from 'gatsby'

export const usePageContent = () => {
    const {
        titlePage: { nodes: nodesTitlePage },
        textButton1: { nodes: nodesTextButton1 },
        allContentfulContactData: { edges: edgesContactDataNodes },
        allContentfulContactDataFields: { edges: edgesContactDataFieldsNodes },
        allContentfulFieldForm: { edges: edgesFieldFormTextNodes },
        FieldFormCheck1: { nodes: nodesFieldFormCheck1 },
        FieldFormArea1: { nodes: nodesFieldFormArea1 },
        allContentfulCheckBoxOptions: { edges: edgesCheckBoxOptionsNodes },
        allContentfulSeo: { nodes: nodesMetaData },
        allContentfulError: { nodes: nodesErrorText },
        allContentfulConditions: { nodes: nodesCondition },
        logoImage: { nodes: nodesLogoImage },
        logoImageDark: { nodes: nodesLogoImageDark },
        formContent: { nodes: edgesFormContent },
        successMessage: { nodes: nodesSuccessMessage },
    } = useStaticQuery(graphql`
        query QueryContactUs {
            titlePage: allContentfulTitlePage(
                filter: { contentful_id: { eq: "3kvfS98Js6FyV3acESUpzp" } }
            ) {
                nodes {
                    node_locale
                    title
                    subTitle
                }
            }
            allContentfulContactData(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        text
                        image {
                            file {
                                url
                            }
                        }
                        order
                        node_locale
                    }
                }
            }
            allContentfulContactDataFields(sort: { fields: order, order: ASC }) {
                edges {
                    node {
                        text
                        image {
                            file {
                                url
                            }
                        }
                        imageDark {
                            file {
                                url
                            }
                        }
                        order
                        node_locale
                    }
                }
            }
            allContentfulFieldForm(
                filter: { type: { eq: "text" } }
                sort: { fields: order, order: ASC }
            ) {
                edges {
                    node {
                        node_locale
                        contentful_id
                        label
                        required
                        id_field
                        type
                        validation
                        order
                    }
                }
            }
            FieldFormCheck1: allContentfulFieldForm(
                filter: { contentful_id: { eq: "795UE734LDsE9THU1IdbeG" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    label
                    required
                    id_field
                    type
                    order
                }
            }
            allContentfulCheckBoxOptions(
                filter: { group: { eq: "795UE734LDsE9THU1IdbeG" } }
                sort: { fields: order, order: ASC }
            ) {
                edges {
                    node {
                        node_locale
                        contentful_id
                        text
                        group
                        idOption
                        order
                    }
                }
            }
            FieldFormArea1: allContentfulFieldForm(
                filter: { contentful_id: { eq: "2HUOajHMOF1UFCAm46bG60" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    label
                    required
                    id_field
                    type
                    order
                }
            }
            allContentfulConditions(filter: { contentful_id: { eq: "1RnJU2FD4zFYvGTQl96Lzh" } }) {
                nodes {
                    paragraph {
                        raw
                    }
                    node_locale
                }
            }
            textButton1: allContentfulTextButton(
                filter: { contentful_id: { eq: "7eIMi7WwISbjlAaAJKGBHy" } }
            ) {
                nodes {
                    node_locale
                    contentful_id
                    text
                }
            }

            allContentfulSeo(filter: { contentful_id: { eq: "6FG81nSQ3PYbi6tbCx7ygh" } }) {
                nodes {
                    title
                    url
                    description {
                        description
                    }
                    image {
                        file {
                            url
                        }
                    }
                    node_locale
                }
            }

            allContentfulError(filter: { contentful_id: { eq: "4pIvnP1Ltt3fCBPghrP4Lx" } }) {
                nodes {
                    node_locale
                    text
                }
            }

            successMessage: allContentfulError(
                filter: { contentful_id: { eq: "3ARSfF1AtON3NOB10fHP4j" } }
            ) {
                nodes {
                    node_locale
                    text
                }
            }

            logoImage: allContentfulAsset(
                filter: { contentful_id: { eq: "3FxG8AP8btHfwd67v7cbTR" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }
            logoImageDark: allContentfulAsset(
                filter: { contentful_id: { eq: "6oVmNK70EXDVUiykx9fPjH" } }
            ) {
                nodes {
                    node_locale
                    file {
                        url
                    }
                }
            }
            formContent: allContentfulFormContent(
                filter: { contentful_id: { eq: "6oPU5NVmA8J1FY78uNhqP3" } }
            ) {
                nodes {
                    node_locale
                    component
                    contentfulfields {
                        label
                        validation
                        order
                        id_field
                        required
                        type
                        node_locale
                    }
                }
            }
        }
    `)

    return {
        nodesTitlePage,
        nodesTextButton1,
        edgesContactDataNodes,
        edgesContactDataFieldsNodes,
        edgesFieldFormTextNodes,
        nodesFieldFormCheck1,
        nodesFieldFormArea1,
        edgesCheckBoxOptionsNodes,
        nodesMetaData,
        nodesErrorText,
        nodesCondition,
        nodesLogoImage,
        nodesLogoImageDark,
        edgesFormContent,
        nodesSuccessMessage,
    }
}
