import './ContactUsForm.scss'
import React, { useState } from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import { useValidationContent } from 'components/hooks/usePageContent/foms/usePageContent'
import Loading from 'components/Loading'
import TuixFieldForm from 'components/TuixFieldForm'
import { FormFieldData, FormValidationMessage } from 'components/TuixFieldForm/Form.interfaces'
import { FieldFormModel } from 'components/TuixFieldForm/TuixFieldForm'
import { getDefaultEmptyValuesFromNode } from 'components/TuixFieldForm/TuixFieldForm.helpers'
import { TuixButtonComponent, TuixTextComponent } from 'components/TuixWebcomponents'
import { useForm } from 'react-hook-form'
import { TextSize, TextVariant } from 'tuix-webcomponents'
import { Node, getDataByLocale } from 'utils/dataHelpers'
import { Locale } from 'utils/enums'
import tuixApiClient from 'utils/tuixClientApi'
import { ContactFormData } from 'utils/types/emailForm.types'

interface Props {
    fieldFormCheck1Nodes: FieldFormModel
    content: React.ReactNode
    textButton1: string
    locale: Locale
    errorText: string
    successText?: string
    formContent: readonly Node<FieldFormModel>[]
}

type CustomEventHandler<DataType = unknown> = (e: CustomEvent<DataType>) => void

interface ContactFormFieldData extends FormFieldData {
    first_name: string
    last_name: string
    email: string
    phone_number: string
    company: string
    message: string
    digital_transformation: boolean
    software_development: boolean
    consulting: boolean
    agree_terms: boolean
}

export default ({
    fieldFormCheck1Nodes,
    content,
    textButton1,
    locale,
    errorText,
    formContent,
    successText,
}: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    const nodeValidationContent = useValidationContent()
    const fieldFormValidations = getDataByLocale<FormValidationMessage>(
        nodeValidationContent,
        locale,
    )

    const textFields = formContent.slice(0, 5)
    const checkBoxOptions = formContent.slice(5, 8)
    const textArea = formContent.slice(8, 9)[0]
    const agreeCheckBox = formContent.slice(9)[0]
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const { handleSubmit, control, reset, watch } = useForm({
        mode: 'all',
        defaultValues: getDefaultEmptyValuesFromNode(formContent),
    })

    const onSendForm = async (formData: ContactFormFieldData) => {
        setIsLoading(true)
        setIsError(false)
        setIsSuccess(false)
        try {
            const dataToSend: ContactFormData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone_number: formData.phone_number,
                company: formData.company,
                digital_transformation: formData.digital_transformation,
                software_development: formData.software_development,
                consulting: formData.consulting,
                message: formData.message,
                agree_terms: formData.agree_terms,
            }
            await tuixApiClient.sendEmail({
                from: process.env.GATSBY_DEFAUL_EMAIL_FROM,
                to: process.env.GATSBY_DEFAUL_EMAIL_FROM,
                subject: 'Contact Form',
                templateUUID: process.env.GATSBY_CONTACT_FORM_EMAIL_TEMPLATE_ID,
                data: JSON.stringify(dataToSend),
            })
            reset()
            setIsSuccess(true)
        } catch (e) {
            setIsError(true)
        }
        setIsLoading(false)
    }

    return (
        <div className="contactFormSection">
            <form className="contactFormContainer">
                <div className="inputsContainer">
                    {textFields.map((textField, index) => (
                        <TuixFieldForm
                            type={textField.type}
                            validation={textField.validation}
                            required={textField.required}
                            id={textField.id_field}
                            label={textField.label}
                            key={index}
                            control={control}
                            fieldFormValidations={fieldFormValidations}
                        />
                    ))}
                </div>
                <TuixTextComponent className="checkboxesText" dark={isDarkMode}>
                    {fieldFormCheck1Nodes.label}
                </TuixTextComponent>
                <div className="checkboxesContainer">
                    {checkBoxOptions.map((option, index) => (
                        <TuixFieldForm
                            label={option.label}
                            type={option.type}
                            id={option.id_field}
                            control={control}
                            fieldFormValidations={fieldFormValidations}
                            key={index}
                        >
                            <TuixTextComponent
                                size={TextSize.Field1}
                                color={TextVariant.PRIMARY}
                                dark={isDarkMode}
                            >
                                {option.label}
                            </TuixTextComponent>
                        </TuixFieldForm>
                    ))}
                </div>
                <TuixFieldForm
                    id={textArea.id_field}
                    label={textArea.label}
                    type={textArea.type}
                    classname={'textAreaContainer'}
                    maxlength={100}
                    control={control}
                    fieldFormValidations={fieldFormValidations}
                />
                <div className="agreeCheckbox">
                    <TuixFieldForm
                        type={agreeCheckBox.type}
                        id={agreeCheckBox.id_field}
                        required={agreeCheckBox.required}
                        control={control}
                        fieldFormValidations={fieldFormValidations}
                    >
                        <TuixTextComponent
                            className="paragraph"
                            size={TextSize.Body2}
                            dark={isDarkMode}
                            color={TextVariant.GREY}
                        >
                            {content as string}
                        </TuixTextComponent>
                    </TuixFieldForm>
                </div>
                <TuixButtonComponent
                    className="submitButton"
                    onClick={handleSubmit(onSendForm) as unknown as CustomEventHandler}
                    disabled={isLoading || !watch('agree_terms')}
                >
                    {textButton1}
                </TuixButtonComponent>
                {isLoading && <Loading />}
                {isError && (
                    <TuixTextComponent
                        size={TextSize.Body1}
                        color={TextVariant.ERROR}
                        dark={isDarkMode}
                        className="resultText"
                    >
                        {errorText}
                    </TuixTextComponent>
                )}
                {isSuccess && (
                    <TuixTextComponent
                        size={TextSize.Body1}
                        color={TextVariant.SUCCESS}
                        dark={isDarkMode}
                        className="resultText"
                    >
                        {successText}
                    </TuixTextComponent>
                )}
            </form>
        </div>
    )
}
