import React from 'react'

import { Locale } from 'utils/enums'
import Page from 'components/Page'

import './index.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ContactDataModel } from 'components/ContactData/ContactData'
import ContactUsData from 'components/ContactUsData'
import ContactUsForm from 'components/ContactUsForm'
import { usePageContent } from 'components/hooks/usePageContent/contactUs/usePageContent'
import Section from 'components/Section'
import { MetaData } from 'components/Seo'
import { FieldFormModel } from 'components/TuixFieldForm/TuixFieldForm'
import { getDataByLocale, mappingEdge, mappingNode } from 'utils/dataHelpers'

import { Asset } from '../../utils/types'

interface Props {
    locale: Locale
}

interface ParagraphModel {
    node_locale: Locale
    contentful_id: string
    paragraph: {
        raw: string
    }
}

interface TextButtonModel {
    text: string
    node_locale: Locale
}

interface TitleModel {
    title: string
    subTitle: string
    node_locale: Locale
}

interface ResultTextModel {
    text: string
    node_locale: Locale
}

interface FormNodeModel {
    contentfulfields: readonly FieldFormModel[]
}

export default ({ locale = Locale.de_DE }: Props): JSX.Element => {
    const {
        nodesTitlePage,
        nodesTextButton1,
        edgesContactDataFieldsNodes,
        nodesFieldFormCheck1,
        nodesMetaData,
        nodesErrorText,
        nodesCondition,
        nodesLogoImage,
        nodesLogoImageDark,
        edgesFormContent,
        nodesSuccessMessage,
    } = usePageContent()

    const metaData = getDataByLocale<MetaData>(nodesMetaData, locale)

    const { title: titlePage, subTitle: subtitlePage } = getDataByLocale<TitleModel>(
        nodesTitlePage,
        locale,
    )
    const { text: textButton1 } = getDataByLocale<TextButtonModel>(nodesTextButton1, locale)
    const { text: errorText } = getDataByLocale<ResultTextModel>(nodesErrorText, locale)
    const { text: successText } = getDataByLocale<ResultTextModel>(nodesSuccessMessage, locale)
    const fieldFormCheck1Nodes = getDataByLocale<FieldFormModel>(nodesFieldFormCheck1, locale)

    const {
        file: { url: logoImageURL },
    } = getDataByLocale<Asset>(nodesLogoImage, locale)
    const {
        file: { url: logoImageDarkURL },
    } = getDataByLocale<Asset>(nodesLogoImageDark, locale)
    const {
        paragraph: { raw: Paragraph1Text },
    } = getDataByLocale<ParagraphModel>(nodesCondition, locale)

    const renderOptions = {
        renderText: (text) => {
            return text.split('\n').reduce((children, textSegment, index) => {
                return [...children, index > 0 && <br key={index} />, textSegment]
            }, [])
        },
    }
    const content = documentToReactComponents(JSON.parse(Paragraph1Text), renderOptions)
    const contactDataFieldsNodes = mappingEdge<ContactDataModel>(
        edgesContactDataFieldsNodes,
        locale,
    )

    const { contentfulfields: formFieldsNodes } = getDataByLocale<FormNodeModel>(
        edgesFormContent,
        locale,
    )
    const formFields = mappingNode(formFieldsNodes, locale)

    return (
        <Page metaData={metaData} locale={locale}>
            <Section className="sectionContainer">
                <ContactUsData
                    titlePage={titlePage}
                    subtitlePage={subtitlePage}
                    logoImageURL={logoImageURL}
                    logoImageDarkURL={logoImageDarkURL}
                    contactDataNodes={contactDataFieldsNodes}
                />
                <ContactUsForm
                    fieldFormCheck1Nodes={fieldFormCheck1Nodes}
                    content={content}
                    textButton1={textButton1}
                    errorText={errorText}
                    locale={locale}
                    formContent={formFields}
                    successText={successText}
                />
            </Section>
        </Page>
    )
}
