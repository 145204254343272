import React from 'react'

import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import './ContactData.scss'
import ImageSwitcher from 'components/ImageSwitcher'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { TextSize } from 'tuix-webcomponents'

import { Locale } from '../../utils/enums'

type Props = {
    text: string
    imageURL: string
    imageDarkURL: string
}

interface File {
    url: string
}

interface Image {
    file: File
}

export interface ContactDataModel {
    text: string
    image: Image
    imageDark: Image
    order: number
    node_locale: Locale
}

export default ({ text, imageURL, imageDarkURL }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()
    return (
        <div className="contactDataInfo_container">
            <div className="img_container">
                <ImageSwitcher
                    lightImageURL={imageURL}
                    darkImageURL={imageDarkURL}
                    className="contactDataInfo_img"
                />
            </div>
            <TuixTextComponent
                size={TextSize.Body1}
                className="contactDataInfo_name"
                dark={isDarkMode}
            >
                {text}
            </TuixTextComponent>
        </div>
    )
}
